export const customerActions = {
  ON_INPUT_CHANGE: "ON_INPUT_CHANGE",
  RESET_STATE: "RESET_STATE",
  SET_CUSTOMER: "SET_CUSTOMER",
};

export const defaultCustomer = {
  id: 0,
  customerName: "",
  customerService: "",
  concernCode: "",
  customerCode: "",
  typeOfCustomer: 0,
  vertical: 0,
  keyClientDirector: "",
  cpd: "",
  cpm: "",
  scdManager: "",
  bcpRequirement: null,
  bcpRequirementComment: "",
  bcpTestRequirement: null,
  bcpTestRequirementComment: "",
  itRecoveryRequirement: null,
  itRecoveryRequirementComment: "",
  bcTolerance: null,
  bcToleranceTimeFrame: null,
  incidentNotification: null,
  incidentNotificationTimeframe: null,
  incidentNotificationMethod: "",
  otherBCPRequirement: "",
  avgWklyInboudOutBoundVolume: null,
  avgWeeklyVesselCalls: null,
  commitmentService: "",
  contractualLiabilityToFailure: null,
  minBusinessContinuityLevel: "",
};

export const typeOfCustomer = [
  {
    label: "Key Client",
    value: 1,
  },
  {
    label: "Runway",
    value: 2,
  },
];

export const vertical = [
  {
    label: "Automotive",
    value: 1,
  },
  {
    label: "Chemicals",
    value: 2,
  },
  {
    label: "FMCG",
    value: 3,
  },
  {
    label: "Lifestyle",
    value: 4,
  },
  {
    label: "Reefer",
    value: 5,
  },
  {
    label: "Retail",
    value: 6,
  },
  {
    label: "Technology",
    value: 7,
  },
  {
    label: "Other",
    value: 8,
  },
];

export const timeframe = [
  {
    label: "Weeks",
    value: 1,
  },
  {
    label: "Days",
    value: 2,
  },
  {
    label: "Hours",
    value: 3,
  },
];

export const customerReducer = (state: any, action: any) => {
  switch (action.type) {
    case customerActions.ON_INPUT_CHANGE:
      return { ...state, [action.name]: action.value };
    case customerActions.SET_CUSTOMER:
      let editableCustomer = action.customer;
      let customer = { ...state };
      customer.id = editableCustomer.id;
      customer.typeOfCustomer = editableCustomer.typeOfCustomerId;
      customer.vertical = editableCustomer.verticalId;
      customer.bcpRequirement = getRequirementValue(
        editableCustomer.bcpRequirement
      );
      customer.bcpTestRequirement = getRequirementValue(
        editableCustomer.bcpTestRequirement
      );
      customer.itRecoveryRequirement = getRequirementValue(
        editableCustomer.itRecoveryRequirement
      );
      customer.avgWklyInboundOutBoundVolume =
        editableCustomer.avgWklyInboudOutBoundVolume;
      customer.incidentNotificationTimeframe =
        editableCustomer.incidentNotificationTimeFrame;
      customer.customerName = editableCustomer.customerName;
      customer.customerService = editableCustomer.customerService;
      customer.concernCode = editableCustomer.concernCode;
      customer.keyClientDirector = editableCustomer.keyClientDirector;
      customer.cpd = editableCustomer.cpd;
      customer.cpm = editableCustomer.cpm;
      customer.scdManager = editableCustomer.scdManager;
      customer.bcpRequirementComment = editableCustomer.bcpRequirementComment;
      customer.bcpTestRequirementComment =
        editableCustomer.bcpTestRequirementComment;
      customer.itRecoveryRequirementComment =
        editableCustomer.itRecoveryRequirementComment;
      customer.bcTolerance = editableCustomer.bcTolerance;
      customer.bcToleranceTimeFrame = editableCustomer.bcToleranceTimeFrame;
      customer.incidentNotification = editableCustomer.incidentNotification;
      customer.incidentNotificationMethod =
        editableCustomer.incidentNotificationMethod;
      customer.otherBCPRequirement = editableCustomer.otherBCPRequirement;
      customer.avgWeeklyVesselCalls = editableCustomer.avgWeeklyVesselCalls;
      customer.avgWklyInboudOutBoundVolume =
        editableCustomer.avgWklyInboudOutBoundVolume;
      customer.commitmentService = editableCustomer.commitmentService;
      customer.contractualLiabilityToFailure =
        editableCustomer.contractualLiabilityToFailure;
      customer.minBusinessContinuityLevel =
        editableCustomer.minBusinessContinuityLevel ?? "";
      customer.customerCode = editableCustomer.customerCode ?? "";
      return { ...customer };
    case customerActions.RESET_STATE:
      return defaultCustomer;
    default:
      return state;
  }
};

export const getRequirementValue = (requirement: any) => {
  if (requirement === null) {
    return null;
  }

  return requirement ? 1 : 2;
};
