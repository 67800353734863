import {
  McInput,
  McOption,
  McSelect,
  McTextAndIcon,
  McTextarea,
} from "@maersk-global/mds-react-wrapper";
import { useContext, useEffect, useReducer, useState } from "react";
import CommonModal from "../../../components/common/commonModal";
import {
  defaultError,
  errorActions,
  errorReducer,
} from "../../../reducers/error";
import styled from "styled-components";
import {
  customerActions,
  customerReducer,
  defaultCustomer,
  timeframe,
  typeOfCustomer,
  vertical,
} from "../reducers/customer";
import { saveGlobalKeyCustomer } from "../../../services/Customers";
import { toast } from "../../../components/common/toast";
import { Customer } from "../../../models/Customer";
import { UserContext } from "../../../components/layout/layout";
import { Roles } from "../../../utils";
import { selectionOptions } from "../../../constants/SelectItems";
import Info from "../../../components/common/info";
import { CustomerInfo } from "../../../constants/Info";

const expandableSections = [
  {
    label: "CustomerInfo",
    isExpanded: false,
  },
  {
    label: "Requirements",
    isExpanded: false,
  },
  {
    label: "Operation Data",
    isExpanded: false,
  },
];

const regex = /^\d*\.?\d{0,2}$/; // Matches decimal values up to 2 decimal places

export default function AddEditCustomer(props: any) {
  const [customer, dispatch] = useReducer(customerReducer, defaultCustomer);
  const [errors, dispatchErrors] = useReducer(errorReducer, defaultError);
  const { roles } = useContext(UserContext);
  useEffect(() => {
    dispatchErrors({ type: errorActions.REMOVE_ERROR });
    if (props.editableCustomer != null) {
      dispatch({
        type: customerActions.SET_CUSTOMER,
        customer: props.editableCustomer,
      });
      handleSectionExpansion(0);
    } else {
      dispatch({ type: customerActions.RESET_STATE });
      handleSectionExpansion(0);
    }
  }, [props.isOpen]);

  const [expandableSection, setExpandableSection] =
    useState(expandableSections);

  const handleSectionExpansion = (index: number) => {
    const newExpandableSections = [...expandableSection];

    newExpandableSections.forEach((section) => {
      section.isExpanded = false;
    });

    newExpandableSections[index].isExpanded =
      !newExpandableSections[index].isExpanded;
    setExpandableSection(newExpandableSections);
  };

  const handleInputChange = (e: any) => {
    dispatch({
      type: customerActions.ON_INPUT_CHANGE,
      name: e.target.name,
      value: e.target.value,
    });
  };

  const handleKeyPress = (e: any) => {
    if ((e.keyCode >= 48 && e.keyCode <= 57) || e.keyCode === 46) {
      let { value } = e.target;
      let updatedValue = `${value}${e.key}`;
      if (
        (regex.test(updatedValue) || value === null || value === "") &&
        parseFloat(updatedValue) <= 100
      ) {
        //Allow to enter
      } else {
        e.preventDefault();
      }
    } else {
      e.preventDefault();
    }
  };

  const onModalClose = () => {
    dispatch({ type: customerActions.RESET_STATE });
    props.onClose();
  };

  const saveCustomer = () => {
    let updatedCustomer = {
      ...customer,
      bcpRequirement: customer.bcpRequirement
        ? customer.bcpRequirement === 1
        : null,
      bcpTestRequirement: customer.bcpTestRequirement
        ? customer.bcpTestRequirement === 1
        : null,
      itRecoveryRequirement: customer.itRecoveryRequirement
        ? customer.itRecoveryRequirement === 1
        : null,
      minBusinessContinuityLevel:
        customer.minBusinessContinuityLevel === ""
          ? null
          : parseFloat(customer.minBusinessContinuityLevel),
    };

    if (!validate(updatedCustomer)) {
      return;
    }

    saveGlobalKeyCustomer(updatedCustomer)
      .then((res) => {
        toast(
          "Success",
          `Key Customer
      ${customer.id === 0 ? "added" : "updated"} successfully`,
          "success"
        );
        props.onSave();
        onModalClose();
      })
      .catch((error) => {
        dispatchErrors({
          type: errorActions.ADD_ERROR,
          errorMessage: [error.message],
        });
      });
  };

  const validate = (customer: Customer) => {
    const errorMessages: string[] = [];

    if (!customer.customerName) {
      errorMessages.push("Customer name is required.");
    }
    if (!customer.customerService) {
      errorMessages.push("Customer service is required.");
    }
    if (!customer.concernCode) {
      errorMessages.push("Concern code is required.");
    }
    if (!customer.customerCode) {
      errorMessages.push("Customer code is required.");
    }
    if (customer.typeOfCustomer === 0) {
      errorMessages.push("Type of customer is required.");
    }
    if (customer.vertical === 0) {
      errorMessages.push("Vertical is required.");
    }

    if (customer.bcpRequirement === null) {
      errorMessages.push("BCP Requirement is required.");
    }

    if (customer.bcpRequirement && !customer.bcpRequirementComment) {
      errorMessages.push(
        "BCP Requirement comment is required when BCP Requirement is Yes."
      );
    }

    if (customer.bcpTestRequirement === null) {
      errorMessages.push("BCP Test Requirement is required.");
    }

    if (customer.bcpTestRequirement && !customer.bcpTestRequirementComment) {
      errorMessages.push(
        "BCP Test Requirement comment is required when BCP Test Requirement is Yes."
      );
    }

    if (customer.itRecoveryRequirement === null) {
      errorMessages.push("IT Recovery Requirement is required.");
    }

    if (
      customer.itRecoveryRequirement &&
      !customer.itRecoveryRequirementComment
    ) {
      errorMessages.push(
        "IT Recovery Requirement comment is required when IT Recovery Requirement is Yes."
      );
    }

    if (errorMessages.length > 0) {
      dispatchErrors({
        type: errorActions.ADD_ERROR,
        errorMessage: errorMessages,
      });
      return false;
    }
    return true;
  };

  return (
    <CommonModal
      primaryActionLabel="Confirm"
      primaryAction={() => saveCustomer()}
      secondaryActionLabel="Cancel"
      onSeceondaryAction={() => onModalClose()}
      isOpen={props.isOpen}
      onModalClose={() => onModalClose()}
      heading={"Add/Edit Key Customer"}
      dimension="medium"
      errors={errors}
      width={674}
    >
      <McTextAndIcon
        label="Customer Info"
        fit="large"
        icon={expandableSection[0].isExpanded ? "chevron-up" : "chevron-down"}
        onClick={() => {
          handleSectionExpansion(0);
        }}
      />
      <TextAndIconBody isExpanded={expandableSection[0].isExpanded}>
        <McInput
          fit="small"
          id="customerName"
          label="Name"
          name="customerName"
          input={(e: any) => handleInputChange(e)}
          value={customer.customerName}
          maxlength={200}
        >
          <Info
            popoverContent={CustomerInfo.AddEditCustomer_Name}
            tooltipText="Name"
          />
        </McInput>
        <McInput
          fit="small"
          id="service"
          label="Service"
          name="customerService"
          input={(e: any) => handleInputChange(e)}
          value={customer.customerService}
          maxlength={200}
        >
          <Info
            popoverContent={CustomerInfo.AddEditCustomer_Service}
            tooltipText="Service"
          />
        </McInput>
        <McInput
          fit="small"
          id="concernCode"
          label="Concern code"
          name="concernCode"
          input={(e: any) => handleInputChange(e)}
          value={customer.concernCode}
          maxlength={100}
        >
          <Info
            popoverContent={CustomerInfo.AddEditCustomer_ConcerCode}
            tooltipText="Concern Code"
          />
        </McInput>
        <McInput
          fit="small"
          id="customerCode"
          label="Customer code"
          name="customerCode"
          input={(e: any) => handleInputChange(e)}
          value={customer.customerCode}
          maxlength={20}
        >
          <Info
            popoverContent={CustomerInfo.AddEditCustomer_ConcerCode}
            tooltipText="Customer Code"
          />
        </McInput>
        <McSelect
          fit="small"
          id="typeOfCustomer"
          label="Type of customer"
          clearbutton
          name="typeOfCustomer"
          placeholder="Select an option"
          optionselected={(e: any) => handleInputChange(e)}
          value={customer.typeOfCustomer}
        >
          <Info
            popoverContent={CustomerInfo.AddEditCustomer_TypeOfCustomer}
            tooltipText="Type of Customer"
          />
          {typeOfCustomer.map((type) => {
            return (
              <McOption key={type.value} value={type.value}>
                {type.label}
              </McOption>
            );
          })}
        </McSelect>
        <McSelect
          fit="small"
          id="vertical"
          label="Vertical"
          name="vertical"
          clearbutton
          placeholder="Select an option"
          optionselected={(e: any) => handleInputChange(e)}
          value={customer.vertical}
        >
          <Info
            popoverContent={CustomerInfo.AddEditCustomer_Vertical}
            tooltipText="Vertical"
          />
          {vertical.map((type) => {
            return (
              <McOption key={type.value} value={type.value}>
                {type.label}
              </McOption>
            );
          })}
        </McSelect>
        <McInput
          fit="small"
          id="keyClientDirector"
          label="Key Client Director "
          name="keyClientDirector"
          input={(e: any) => handleInputChange(e)}
          value={customer.keyClientDirector}
          maxlength={100}
        >
          <Info
            popoverContent={CustomerInfo.AddEditCustomer_KeyClientDirector}
            tooltipText="Key Client Director"
          />
        </McInput>
        <McInput
          fit="small"
          id="cpd"
          label="Client Program Director, CPD"
          name="cpd"
          input={(e: any) => handleInputChange(e)}
          value={customer.cpd}
          maxlength={100}
        >
          <Info
            popoverContent={CustomerInfo.AddEditCustomer_CPD}
            tooltipText="Client Program Director, CPD"
          />
        </McInput>
        <McInput
          fit="small"
          id="cpm"
          label="Client Program Manager, CPM"
          name="cpm"
          input={(e: any) => handleInputChange(e)}
          value={customer.cpm}
          maxlength={100}
        >
          <Info
            popoverContent={CustomerInfo.AddEditCustomer_CPM}
            tooltipText="Client Program Manager, CPM"
          />
        </McInput>
        <McInput
          fit="small"
          id="scdManager"
          label="Supply Chain Development, SCD"
          name="scdManager"
          input={(e: any) => handleInputChange(e)}
          value={customer.scdManager}
          maxlength={100}
        >
          <Info
            popoverContent={CustomerInfo.AddEditCustomer_SCD}
            tooltipText="Supply Chain Development, SCD"
          />
        </McInput>
      </TextAndIconBody>
      <McTextAndIcon
        label="Requirements"
        fit="large"
        icon={expandableSection[1].isExpanded ? "chevron-up" : "chevron-down"}
        onClick={() => {
          handleSectionExpansion(1);
        }}
      />
      <TextAndIconBody isExpanded={expandableSection[1].isExpanded}>
        <McSelect
          fit="small"
          id="bcpRequirement"
          label="BCP Requirement"
          clearbutton
          name="bcpRequirement"
          placeholder="Select an option"
          optionselected={(e: any) => handleInputChange(e)}
          value={customer.bcpRequirement}
        >
          <Info
            popoverContent={CustomerInfo.AddEditCustomer_BCPRequirement}
            tooltipText="Requirements"
          />
          {selectionOptions.map((option) => {
            return (
              <McOption key={option.label} value={option.value}>
                {option.label}
              </McOption>
            );
          })}
        </McSelect>
        <McTextarea
          name="bcpRequirementComment"
          label="BCP Requirement Comment"
          rows={5}
          fit={"small"}
          input={(e: any) => handleInputChange(e)}
          value={customer.bcpRequirementComment}
          maxlength={200}
        >
          <Info
            popoverContent={CustomerInfo.AddEditCustomer_BCPRequirementComment}
            tooltipText="BCP Requirement Comment"
          />
        </McTextarea>
        <McSelect
          fit="small"
          id="bcpTestRequirement"
          label="BCP Test Requirement"
          name="bcpTestRequirement"
          clearbutton
          placeholder="Select an option"
          optionselected={(e: any) => handleInputChange(e)}
          value={customer.bcpTestRequirement}
        >
          <Info
            popoverContent={CustomerInfo.AddEditCustomer_BCPTestRequirement}
            tooltipText="BCP Test Requirement"
          />
          {selectionOptions.map((option) => {
            return (
              <McOption key={option.label} value={option.value}>
                {option.label}
              </McOption>
            );
          })}
        </McSelect>
        <McTextarea
          name="bcpTestRequirementComment"
          label="BCP Test Requirement Comment"
          rows={5}
          fit={"small"}
          input={(e: any) => handleInputChange(e)}
          value={customer.bcpTestRequirementComment}
          maxlength={200}
        >
          <Info
            popoverContent={
              CustomerInfo.AddEditCustomer_BCPTestRequirementComment
            }
            tooltipText="BCP Test Requirement Comment"
          />
        </McTextarea>
        <McSelect
          fit="small"
          id="itRecoveryRequirement"
          label="IT Recovery Requirement"
          name="itRecoveryRequirement"
          clearbutton
          placeholder="Select an option"
          optionselected={(e: any) => handleInputChange(e)}
          value={customer.itRecoveryRequirement}
        >
          <Info
            popoverContent={CustomerInfo.AddEditCustomer_ITRecoveryRequirement}
            tooltipText="IT Recovery Requirement"
          />
          {selectionOptions.map((option) => {
            return (
              <McOption key={option.label} value={option.value}>
                {option.label}
              </McOption>
            );
          })}
        </McSelect>
        <McTextarea
          name="itRecoveryRequirementComment"
          label="IT Recovery Requirement Comment"
          rows={5}
          fit={"small"}
          input={(e: any) => handleInputChange(e)}
          value={customer.itRecoveryRequirementComment}
          maxlength={200}
        >
          <Info
            popoverContent={
              CustomerInfo.AddEditCustomer_ITRecoveryRequirementComment
            }
            tooltipText="IT Recovery Requirement Comment"
          />
        </McTextarea>
        <GridWrapper>
          <McInput
            fit="small"
            id="bcTolerance"
            label="BC Tolerance"
            name="bcTolerance"
            input={(e: any) => handleInputChange(e)}
            value={customer.bcTolerance}
            type="number"
          >
            <Info
              popoverContent={CustomerInfo.AddEditCustomer_BCTolerance}
              tooltipText="BC Tolerance"
            />
          </McInput>
          <McSelect
            fit="small"
            id="bcToleranceTimeFrame"
            label="Timeframe"
            placeholder="Select an option"
            name="bcToleranceTimeFrame"
            clearbutton
            optionselected={(e: any) => handleInputChange(e)}
            value={customer.bcToleranceTimeFrame}
          >
            <Info
              popoverContent={CustomerInfo.AddEditCustomer_BCToleranceTimeFrame}
              tooltipText="Timeframe"
            />
            {timeframe.map((option) => {
              return (
                <McOption key={option.value} value={option.value}>
                  {option.label}
                </McOption>
              );
            })}
          </McSelect>
        </GridWrapper>
        <GridWrapper>
          <McInput
            fit="small"
            id="incidentNotification"
            label="Incident Notification"
            name="incidentNotification"
            input={(e: any) => handleInputChange(e)}
            value={customer.incidentNotification}
            type="number"
          >
            <Info
              popoverContent={CustomerInfo.AddEditCustomer_IncidentNotification}
              tooltipText="Incident Notification"
            />
          </McInput>
          <McSelect
            fit="small"
            id="incidentNotificationTimeframe"
            label="Timeframe"
            placeholder="Select an option"
            clearbutton
            name="incidentNotificationTimeframe"
            optionselected={(e: any) => handleInputChange(e)}
            value={customer.incidentNotificationTimeframe}
          >
            <Info
              popoverContent={
                CustomerInfo.AddEditCustomer_IncidentNotificationTimeframe
              }
              tooltipText="Timeframe"
            />
            {timeframe.map((option) => {
              return (
                <McOption key={option.value} value={option.value}>
                  {option.label}
                </McOption>
              );
            })}
          </McSelect>
        </GridWrapper>
        <McTextarea
          name="incidentNotificationMethod"
          label="Incident notification method"
          rows={5}
          fit={"small"}
          input={(e: any) => handleInputChange(e)}
          value={customer.incidentNotificationMethod}
          maxlength={100}
        >
          <Info
            popoverContent={
              CustomerInfo.AddEditCustomer_IncidentNotificationMethod
            }
            tooltipText="Incident Notification Method"
          />
        </McTextarea>
        <McTextarea
          label="Other BC requirements"
          rows={5}
          fit={"small"}
          name="otherBCPRequirement"
          input={(e: any) => handleInputChange(e)}
          value={customer.otherBCPRequirement}
          maxlength={200}
        >
          <Info
            popoverContent={CustomerInfo.AddEditCustomer_OtherBCRequirements}
            tooltipText="Other BC Requirements"
          />
        </McTextarea>
      </TextAndIconBody>
    </CommonModal>
  );
}

export const TextAndIconBody = styled.div<{ isExpanded?: boolean }>`
  padding: 5px 10px 10px 10px;
  display: ${({ isExpanded }) => (isExpanded ? "" : "none")};
`;

export const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  align-content: center;
`;
