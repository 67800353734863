import SiteTabs from "./SiteTabs";
import { TabItemTypes, tabItems } from "../../constants/TabItems";
import { Button } from "../../components/common/button";
import Welcome from "../Welcome";
import { createContext, useContext, useEffect, useState } from "react";
import useSite from "../../hooks/useSite";
import { McTag } from "@maersk-global/mds-react-wrapper/components-core/mc-tag";
import { siteBCPStatus } from "../../constants/Site";
import {
  startBCMReview,
  submitBCMReview,
  updateBCMStatus,
} from "../../services/Site/Review";
import { toast, toastError } from "../../components/common/toast";
import CommonModal from "../../components/common/commonModal";
import { McTextarea } from "@maersk-global/mds-react-wrapper";
import { UserContext } from "../../components/layout/layout";
import {
  SiteActions,
  SiteModes,
  SiteRoleStatusMapping,
} from "../../constants/SiteBCM";
import styled from "styled-components";
import { getSiteWidgetDetails } from "../../services/Site/Widgets";
import RightSideNav from "./RightSideNav";
import Widget from "./Widget/Widget";
import Box from "../../components/common/box";

export const ModeContext = createContext<string>(SiteModes.Read);

export default function SiteBCM({
  isSiteAssignedToUser = false,
}: Readonly<{ isSiteAssignedToUser?: boolean }>) {
  const [status, setStatus] = useState(siteBCPStatus.NotStarted);
  const [loading, setLoading] = useState(false);
  const [canSubmitBCM, setCanSubmitBCM] = useState(true);
  const [actionModalOpen, setActionModalOpen] = useState(false);
  const [siteMode, setSiteMode] = useState<string>(SiteModes.Read);
  const [actions, setActions] = useState<string[]>([]);
  const [comments, setComments] = useState<string>("");
  const [action, setAction] = useState<string>("");
  const [widgetData, setWidgetData] = useState<any>();
  const [shouldShowWidget, setShouldShowWidget] = useState(true);

  const { siteId, site, updateSingleSite } = useSite();
  const { roles } = useContext(UserContext);
  useEffect(() => {
    setStatus(site.status ?? siteBCPStatus.NotStarted);

    let role = roles[0];
    if (isSiteAssignedToUser) {
      let permissions = SiteRoleStatusMapping[role].find(
        (item) => item.Status === site.status
      );

      if (permissions) {
        setSiteMode(permissions.Mode);
        setActions(permissions.Actions);
      }
    } else {
      setSiteMode(SiteModes.Read);
      setActions([]);
    }
  }, [siteId, site.status, siteMode]);

  useEffect(() => {
    if (siteId > 0) getWidgetDetails();
  }, [siteId]);

  const getWidgetDetails = () => {
    getSiteWidgetDetails(site.id, site.versionId)
      .then((res) => {
        setWidgetData(res);
      })
      .catch((error) => {
        toastError("Error", error.message);
      });
  };

  const onActionClick = (action: string) => {
    setAction(action);
    setActionModalOpen(true);
  };

  const onModalClose = () => {
    setActionModalOpen(false);
  };

  const submitReview = () => {
    if (action === "submit") {
      submitBCMReview({
        siteId: site.id,
        versionId: site.versionId,
        comments: comments,
      })
        .then((res) => {
          updateSiteStatus(siteBCPStatus.PendingApproval, action);
        })
        .catch((error) => {
          toast("Error", error.message, "error");
        });
    } else {
      if (
        (action === "reworkinprogress" || action === "rejected") &&
        comments.trim().length === 0
      ) {
        toast("Error", "Please provide comments", "error");
        return;
      }
      updateBCMStatus({
        siteId: site.id,
        versionId: site.versionId,
        comments: comments,
        status: action,
      })
        .then((res) => {
          updateSiteStatus(action, action);
        })
        .catch((error) => {
          toast("Error", error.message, "error");
        });
    }
  };

  const updateSiteStatus = (status: string, action: string) => {
    let updatedSite = site;
    updatedSite.status = status;
    setStatus(status);
    updateSingleSite(updatedSite);
    onModalClose();
    toast("Success", gettoastMessage(action), "success");
  };

  const gettoastMessage = (action: string) => {
    switch (action) {
      case SiteActions.Start:
        return "Review started successfully";
      case SiteActions.Submit:
        return "Review submitted successfully";
      case SiteActions.Approve:
        return "Review approved successfully";
      case SiteActions.Validated:
        return "Review validated successfully";
      case SiteActions.Rework:
        return "Review sent for rework";
      case SiteActions.Reject:
        return "Review rejected";
      default:
        return "";
    }
  };

  const getConfirmationMessage = (action: string) => {
    switch (action) {
      case SiteActions.Submit:
        return "Are you sure you want to submit the plan?";
      case SiteActions.Approve:
        return "Are you sure you want to approve the plan?";
      case SiteActions.Validated:
        return "Are you sure you want to validate the plan?";
      case SiteActions.Rework:
        return "Are you sure you want to send the plan for rework?";
      case SiteActions.Reject:
        return "Are you sure you want to reject the plan?";
      default:
        return "";
    }
  };

  const startReview = () => {
    startBCMReview(site.id)
      .then((res) => {
        let updatedSite = site;

        updatedSite.status = siteBCPStatus.InProgress;
        updatedSite.versionId = res;
        updateSingleSite(updatedSite);

        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast("Error", error.message, "error");
      });
  };

  const getSiteBcpStatusTag = (status: string) => {
    switch (status) {
      case siteBCPStatus.NotStarted:
        return (
          <McTag label="Not Started" fit="large" appearance="neutral-inverse" />
        );
      case siteBCPStatus.InProgress:
        return (
          <McTag label="Inprogress" fit="large" appearance="neutral-weak" />
        );
      case siteBCPStatus.PendingApproval:
        return <McTag label="Pending Approval" fit="large" appearance="info" />;
      case siteBCPStatus.PendingValidation:
        return (
          <McTag label="Pending Validation" fit="large" appearance="info" />
        );
      case siteBCPStatus.Validated:
        return <McTag label="Validated" fit="large" appearance="success" />;
      case siteBCPStatus.ReworkInProgress:
        return (
          <McTag label="Rework In Progress" fit="large" appearance="warning" />
        );
      case siteBCPStatus.Rejected:
        return <McTag label="Rejected" fit="large" appearance="error" />;
      default:
        return <></>;
    }
  };

  const mapActions = (action: string) => {
    switch (action) {
      case SiteActions.Submit:
        return (
          <Button
            fit="large"
            click={() => onActionClick(SiteActions.Submit)}
            label="Submit"
            disabled={canSubmitBCM}
            key={siteId}
          />
        );
      case SiteActions.Approve:
        return (
          <Button
            fit="large"
            click={() => onActionClick(SiteActions.Approve)}
            label="Approve"
            disabled={canSubmitBCM}
            key={siteId}
            appearance="primary"
            variant="filled"
          />
        );
      case SiteActions.Validated:
        return (
          <Button
            fit="large"
            click={() => onActionClick(SiteActions.Validated)}
            label="Validate"
            disabled={canSubmitBCM}
            key={siteId}
            appearance="primary"
            variant="filled"
            className="site-actions-validate"
          />
        );

      case SiteActions.Rework:
        return (
          <Button
            fit="large"
            click={() => onActionClick(SiteActions.Rework)}
            label="Rework"
            appearance="error"
            disabled={canSubmitBCM}
            key={siteId}
            className="site-actions-rework"
          />
        );
      case SiteActions.Start:
        return (
          <Button
            fit="large"
            click={() => {
              setLoading(true);
              startReview();
            }}
            label="Start"
            key={siteId}
            loading={loading}
          />
        );
      case SiteActions.Reject:
        return (
          <Button
            fit="large"
            click={() => onActionClick(SiteActions.Reject)}
            label="Reject"
            appearance="error"
            disabled={canSubmitBCM}
            key={siteId}
          />
        );
      default:
        return <></>;
    }
  };

  const onAllItemsSubmitted = (isAllKeysSubmitted: boolean) => {
    getWidgetDetails();
    setCanSubmitBCM(!isAllKeysSubmitted);
  };

  return site.status === siteBCPStatus.NotStarted ? (
    <>
      <Welcome
        heading="Business Continuity Management"
        subHeading="Start your review process by clicking below."
        buttonLabel="Start Review"
        icon="play"
        onButtonClick={() => {
          setLoading(true);
          startReview();
        }}
        isButtonLoadingEnabled={loading}
        isDisabled={siteMode === SiteModes.Read}
      />
    </>
  ) : (
    <>
      <CommonModal
        primaryActionLabel="Confirm"
        primaryAction={submitReview}
        secondaryActionLabel="Cancel"
        onSeceondaryAction={onModalClose}
        isOpen={actionModalOpen}
        onModalClose={onModalClose}
        heading={"Submit Business Continuity Plan"}
        dimension="medium"
      >
        {getConfirmationMessage(action)}
        <McTextarea
          fit="large"
          label="Comments"
          maxlength={300}
          input={(e: any) => setComments(e.target.value)}
        />
      </CommonModal>
      <div style={{ marginRight: "40px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex", gap: "10px" }}>
            <div className="site-heading">{site.code ?? ""}</div>
            {getSiteBcpStatusTag(site.status ?? "")}
            <div style={{ fontWeight: "bold", fontSize: "14px" }}>
              {widgetData?.completionPercentage ?? ""}%
            </div>
          </div>
          <ActionsWrapper>
            <div className="button-list">
              <Button
                icon={shouldShowWidget ? "eye" : "eye-slash"}
                label=""
                fit="large"
                appearance="neutral"
                variant="outlined"
                click={() => setShouldShowWidget(!shouldShowWidget)}
              />
              {actions.map((action) => {
                return <div key={action}>{mapActions(action)}</div>;
              })}
            </div>
          </ActionsWrapper>
        </div>
        <div>
          <ModeContext.Provider value={siteMode}>
            <Box style={{ display: !shouldShowWidget ? "none" : "" }}>
              <Widget site={site} siteId={siteId} key="bcm" />
            </Box>
            <SiteTabs
              items={tabItems[TabItemTypes.SITE]}
              key={site.versionId}
              onItemSaved={onAllItemsSubmitted}
            />
          </ModeContext.Provider>
        </div>
      </div>
      <RightSideNav siteId={site.id} versionId={site.versionId} />
    </>
  );
}

export const ActionsWrapper = styled.div`
  .button-list {
    display: flex;
    gap: 10px;
  }

  .site-actions-validate::part(button) {
    background-color: var(
      --mds_brand_appearance_success_default_background-color
    );
  }
  .site-actions-rework::part(button) {
    background-color: var(
      --mds_brand_appearance_warning_default_background-color
    );
  }
`;
