import { useContext, useEffect, useReducer, useState } from "react";
import Welcome from "../Welcome";
import AddEditCustomer from "./modals/addEditCustomer";
import { McTable, McTag } from "@maersk-global/mds-react-wrapper";
import Heading from "../../components/common/heading";
import { Button } from "../../components/common/button";
import { loadingIndicator } from "../../components/common/loading";
import {
  getGlobalKeyCustomers,
  saveKeyCustomerSitesMapping,
} from "../../services/Customers";
import { toast } from "../../components/common/toast";
import {
  DeleteIcon,
  EditIcon,
  StaticIcon,
} from "../../components/common/icons";
import {
  defaultDeleteEntityState,
  deleteActions,
  deleteReducer,
  onDeleteEntity,
} from "../../reducers/delete";
import DeleteModal from "../../components/common/deleteModal";
import SiteMapping from "../Sites/modals/siteMapping";
import ExpandedTableContent from "../../components/common/expandedTableContent";
import Tooltip from "../../components/common/tooltip";
import Box from "../../components/common/box";
import { UserContext } from "../../components/layout/layout";
import { Roles } from "../../utils";
import Info from "../../components/common/info";
import { CustomerInfo } from "../../constants/Info";
import Action from "../../components/common/action";

export default function Customers() {
  const [deleteEntityState, dispatchDelete] = useReducer(
    deleteReducer,
    defaultDeleteEntityState
  );
  const [loading, setLoading] = useState(true);
  const [customers, setCustomers] = useState<any>([]);

  const [keyCustomersModalOpen, setKeyCustomersModalOpen] = useState(false);
  const [editableCustomer, setEditableCustomer] = useState(null);
  const [isSiteMappingModalOpen, setIsSiteMappingModalOpen] = useState(false);
  const [siteMappingCustomer, setSiteMappingCustomer] = useState<any>(null);
  const { roles } = useContext(UserContext);

  useEffect(() => {
    getAllKeyCustomers();
  }, []);

  const getAllKeyCustomers = () => {
    getGlobalKeyCustomers()
      .then((response) => {
        setCustomers(response);
        setLoading(false);
      })
      .catch((error) => {
        toast("Error", error, "error");
      });
  };

  const onAddNewKeyCustomerClick = () => {
    setEditableCustomer(null);
    setKeyCustomersModalOpen(true);
  };

  const onSaveCustomer = () => {
    getAllKeyCustomers();
  };

  const onEditClick = (id: string) => {
    const customer = customers.find((customer: any) => customer.id === id);
    if (customer) {
      setEditableCustomer(customer);
      setKeyCustomersModalOpen(true);
    }
  };

  const closeMapCustomersToSitesModal = () => {
    setSiteMappingCustomer(null);
    setIsSiteMappingModalOpen(false);
  };

  const mapKeyCustomerToSites = (siteIds: number[], id: number) => {
    let singleCustomer = customers.find((customer: any) => customer.id === id);

    saveKeyCustomerSitesMapping({
      keyCustomerId: id,
      siteIds: siteIds,
    })
      .then((res) => {
        toast(
          "Success",
          `User 
        ${singleCustomer.customerName} sites mapped successfully`,
          "success"
        );
      })
      .catch((error) => {
        toast("error", error.message, "error");
      })
      .finally(() => {
        getAllKeyCustomers();
        setSiteMappingCustomer(null);
        setIsSiteMappingModalOpen(false);
      });
  };

  const assignCustomerSites = (keyCustomer: any) => {
    let singleCustomer = customers.find(
      (customer: any) => customer.id === keyCustomer.id
    );

    if (singleCustomer) {
      setSiteMappingCustomer(singleCustomer);
      setIsSiteMappingModalOpen(true);
    }
  };

  return (
    <>
      <SiteMapping
        isOpen={isSiteMappingModalOpen}
        onClose={closeMapCustomersToSitesModal}
        onSave={mapKeyCustomerToSites}
        entity={siteMappingCustomer}
        sites={siteMappingCustomer?.sites || []}
        secondHeading="Sites Assigned to Key Customer"
      />
      <AddEditCustomer
        isOpen={keyCustomersModalOpen}
        editableCustomer={editableCustomer}
        onSave={onSaveCustomer}
        onClose={() => {
          setEditableCustomer(null);
          setKeyCustomersModalOpen(false);
        }}
      />
      <DeleteModal
        isOpen={deleteEntityState.isModalOpen}
        onSave={onSaveCustomer}
        deleteEntity={deleteEntityState.deleteEntity}
        onClose={() => {
          dispatchDelete({
            type: deleteActions.SET_DELETE_MODAL_CLOSE,
          });
        }}
      />
      {loading && loadingIndicator}
      {!loading && customers.length === 0 && (
        <Welcome
          heading={"Key Customer"}
          subHeading={
            "There's no key customers listed. Start adding one or more."
          }
          buttonLabel={"Add Key Customer"}
          onButtonClick={onAddNewKeyCustomerClick}
        />
      )}
      {!loading && customers.length > 0 && (
        <>
          <Heading
            heading={"Customers"}
            subheading={
              <Info
                popoverContent={CustomerInfo.Page_Subheading}
                tooltipText="Configure global key customers here."
              />
            }
          >
            <Button
              id="add_global_key_customer"
              label="Add new Key Customer"
              name="add new Key Customer"
              click={onAddNewKeyCustomerClick}
              variant="filled"
              fit="large"
              appearance="primary"
              icon="user-key"
            />
          </Heading>
          <McTable
            disableroundedcorners
            data={customers}
            expand
            columns={[
              { id: "customerName", label: "Name" },
              { id: "customerService", label: "Service" },
              { id: "concernCode", label: "Concern Code" },
              { id: "customerCode", label: "Customer Code" },
              { id: "typeOfCustomer", label: "Type Of Customer" },
              { id: "vertical", label: "Vertical" },
              { id: "keyClientDirector", label: "Key Client Director " },
              { id: "cpd", label: "Client Program Director, CPD" },
              { id: "cpm", label: "Client Program Manager, CPM" },
              { id: "scdManager", label: "Supply Chain Development, SCD" },
              { id: "sites", label: "Assigned Sites" },
              { id: "id", label: "", sticky: "right", sortDisabled: true },
            ]}
            fit="small"
          >
            {customers.map((row: any) => (
              <>
                <div key={`${row.id}_sites`} slot={`${row.id}_sites`}>
                  <McTag
                    fit="small"
                    appearance="info"
                    label={`${row.sites.length}`}
                  >
                    <span slot="trailingicon">
                      {row.sites && (
                        <Tooltip content="Click Here to Assign Site(s) to Customer.">
                          <StaticIcon
                            icon="plus"
                            slot="trigger"
                            color="#0077C8"
                            size="20"
                            onClick={() => {
                              assignCustomerSites(row);
                            }}
                          />
                        </Tooltip>
                      )}
                    </span>
                  </McTag>
                </div>
                <div key={`${row.id}_id`} slot={`${row.id}_id`}>
                  <Box display="flex" justifyContent="flex-end">
                    <Action
                      items={[
                        {
                          label: "Edit",
                          onClick: () => onEditClick(row.id),
                        },
                        {
                          label: "Delete",
                          onClick: () =>
                            onDeleteEntity(
                              dispatchDelete,
                              row.id,
                              "Key Customer",
                              row.customerName,
                              "GlobalKeyCustomer",
                              ""
                            ),
                        },
                      ]}
                    />
                  </Box>
                </div>
                <div slot={`${row.id}_expanded`} style={{ maxWidth: "60%" }}>
                  <Box display="grid" gridTemplateColumns="0.5fr 0.5fr">
                    <Box>
                      <ExpandedTableContent
                        title="BCP Requirement"
                        value={row.bcpRequirement ? "Yes" : "No"}
                      />
                      <ExpandedTableContent
                        title="BCP Requirement Comment"
                        value={row.bcpRequirementComment || "N/A"}
                      />
                      <ExpandedTableContent
                        title="BCP Test Requirement"
                        value={row.bcpTestRequirement ? "Yes" : "No"}
                      />
                      <ExpandedTableContent
                        title="BCP Test Requirement Comment"
                        value={row.bcpTestRequirementComment || "N/A"}
                      />
                      <ExpandedTableContent
                        title="IT Recovery Requirement"
                        value={row.itRecoveryRequirement ? "Yes" : "No"}
                      />
                      <ExpandedTableContent
                        title="IT Recovery Requirement Comment"
                        value={row.itRecoveryRequirementComment || "N/A"}
                      />
                      <ExpandedTableContent
                        title="BC Tolerance Value"
                        value={row.bcToleranceValue || "N/A"}
                      />
                      <ExpandedTableContent
                        title="Incident Notification Value"
                        value={row.incidentNotificationValue || "N/A"}
                      />
                    </Box>
                    <Box>
                      <ExpandedTableContent
                        title="Incident Notification Method"
                        value={row.incidentNotificationMethod || "N/A"}
                      />
                      <ExpandedTableContent
                        title="Other BCP Requirement"
                        value={row.otherBCPRequirement || "N/A"}
                      />
                      {!roles.includes(Roles.GSCADMIN) ? (
                        <>
                          <ExpandedTableContent
                            title="Average Weekly Inbound/Outbound Volume"
                            value={row.avgWklyInboudOutBoundVolume || "N/A"}
                          />

                          <ExpandedTableContent
                            title="Average Weekly Vessel Calls"
                            value={row.avgWeeklyVesselCalls || "N/A"}
                          />
                          <ExpandedTableContent
                            title="Commitment Service/Order fullfilment level"
                            value={row.commitmentService || "N/A"}
                          />
                          <ExpandedTableContent
                            title="Min business Continuity Level"
                            value={row.minBusinessContinuityLevel || "N/A"}
                          />
                          <ExpandedTableContent
                            title="Contractual Liability associated to service failure"
                            value={row.contractualLiabilityToFailure || "N/A"}
                          />
                        </>
                      ) : null}
                    </Box>
                  </Box>
                </div>
              </>
            ))}
          </McTable>
        </>
      )}
    </>
  );
}
